import React from 'react';
import {navigate} from 'gatsby-link';
import styled from 'styled-components';
import {Form, Input, message} from 'antd';
import * as AppContext from '../AppContext';
import ModalContent from './ModalContent';
import Button from './Button';

function ChangePwModalContent(props) {
  const {onClose} = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const app = React.useContext(AppContext.Context);

  const _onChangePwSubmit = React.useCallback(async (values) => {
    setLoading(true);
    try {
      let resp = await app.actions.changePw({
        password: values.password,
        newPassword: values.newPassword,
      });
      message.success('密碼已更新！');
      onClose();
    } catch (err) {
      //
      setError('請確認您的密碼是否正確');
    }
    setLoading(false);
  }, []);

  return (
    <ModalContent
      title={'變更密碼'}
      contentCss={'padding: 0px;'}
      wrapperCss={'max-width: 360px;'}>
      <Container>
        <div className="content">
          <Form
            onChange={(e) => setError('')}
            className="change-pw-form"
            initialValues={{
              password: '',
              newPassword: '',
              confirmNewPassword: '',
            }}
            form={form}
            name="change-pw"
            onFinish={_onChangePwSubmit}
            style={{width: '100%'}}>
            <Form.Item
              name="password"
              rules={[{required: true, message: '請輸入密碼'}]}
              style={{marginBottom: 10}}>
              <Input.Password placeholder="密碼" size="large" />
            </Form.Item>
            <Form.Item
              name="newPassword"
              rules={[
                {required: true, message: '請輸入新密碼'},
                {
                  pattern: /^.{8}/,
                  message: '密碼至少須為八碼！',
                },
              ]}
              style={{marginBottom: 10}}>
              <Input.Password placeholder="新密碼" size="large" />
            </Form.Item>
            <Form.Item
              name="confirmNewPassword"
              rules={[
                {required: true, message: '請再次輸入密碼'},
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('確認密碼不一致'));
                  },
                }),
              ]}
              style={{marginBottom: 10}}>
              <Input.Password placeholder="確認密碼" size="large" />
            </Form.Item>
            <Form.Item>
              {error && <div className="error-hint">{error}</div>}
              <Button
                htmlType="submit"
                size="small"
                loading={loading}
                disabled={loading}
                style={{width: '100%', marginBottom: 10}}
                shadow>
                變更密碼
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Container>
    </ModalContent>
  );
}

const Container = styled.div`
  padding-top: 16px;
  & > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
  }

  & .error-hint {
    color: #ff4d4f;
    margin-bottom: 5px;
    text-align: center;
  }
`;

export default ChangePwModalContent;
