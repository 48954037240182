import React, {Component} from 'react';
import styled from 'styled-components';

function CopyToClipboard(props) {
  const {
    text = 'example text to copy',
    children = null,
    showTooltip = true,
    onCopy = () => 0,
  } = props;
  const [copied, setCopied] = React.useState(false);
  let _dissmissCopiedStateCountDown = React.useRef().current;
  let _copyTextRef = React.useRef().current;

  const _onCopied = React.useCallback(() => {
    setCopied(true);
    _dissmissCopiedStateCountDown = setTimeout(() => {
      _dissmissCopiedStateCountDown &&
        clearTimeout(_dissmissCopiedStateCountDown);
      _dissmissCopiedStateCountDown = null;
      setCopied(false);
    }, 1000);
  }, [copied]);

  const _copyToClipboard = React.useCallback(async () => {
    try {
      // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
      try {
        await navigator.clipboard.writeText(text);
      } catch (err) {
        // support older browser
        _copyTextRef && _copyTextRef.select();
        document.execCommand('copy');
      }
      _onCopied();
      onCopy();
    } catch (err) {
      console.log('>>', err);
    }
  }, [text, _onCopied]);

  return (
    <Wrapper>
      <div>
        <textarea
          style={{opacity: 0, position: 'absolute', width: 0, height: 0}}
          ref={(textarea) => (_copyTextRef = textarea)}
          value={text}
          onChange={() => 0}
        />
      </div>
      <div className="action-btn-container" onClick={_copyToClipboard}>
        {children || 'Copy'}
        {showTooltip && (
          <div className="tooltip">{copied ? 'copied!' : 'copy'}</div>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  --tooltip-bg-color: rgba(0, 0, 0, 0.5);
  --tooltip-height: 7px;
  --tooltip-height-inverse: -7px;
  & .action-btn-container {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
  }
  & .tooltip {
    position: absolute;
    pointer-events: none;
    background-color: var(--tooltip-bg-color);
    padding: 6px 9px;
    top: 10px;
    transform: translateY(-100%) translateY(var(--tooltip-height-inverse));
    font-size: 14px;
    border-radius: 30px;
    color: white;
    opacity: 0;
    transition: opacity 0.3s, top 0.3s;
  }
  & .tooltip:after {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    border: none;
    border-top: var(--tooltip-height) solid var(--tooltip-bg-color);
    border-left: var(--tooltip-height) solid transparent;
    border-right: var(--tooltip-height) solid transparent;
    bottom: var(--tooltip-height-inverse);
    left: 50%;
    transform: translateX(var(--tooltip-height-inverse));
  }
  & .action-btn-container:hover .tooltip {
    opacity: 1;
    top: -3px;
  }
`;

export default CopyToClipboard;
