import React, {useState} from 'react';
import {navigate} from 'gatsby-link';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import URL_PATH from '../../UrlPath';
import LoginModal from '../../components/LoginModal';
import Button from '../../components/Button';
import imgLoginCake from '../../images/login-cake.jpeg';
import ProfileSideNav from '../../components/ProfileSideNav';
import {Button as AntButton, message, Card, Tabs} from 'antd';
import AddressInput from '../../components/AddressInput';
import TextInput from '../../components/TextInput';
import useDimension from '../../hooks/use-dimension';
import PhoneVerifyModal from '../../components/PhoneVerifyModal';
import CopyToClipboard from '../../components/CopyToClipboard';
import * as Icon from '../../components/Icon';
import ChangePwModalContent from '../../components/ChangePwModalContent';
import {isChineseName} from '../../domain/FormatValidator';
import Google from '../../images/google.png';
import Facebook from '../../images/facebook.png';
import * as PropTypes from 'prop-types';
import imgLogo from '../../images/logo.png';
import {useOutlet} from 'reconnect.js';

const RequiredFields = [
  'name',
  'email',
  'city',
  'district',
  'address',
  'birthdate',
];

const AuthCard = (props) => {
  const {type, onClick} = props;
  const app = React.useContext(AppContext.Context);
  const isLogin = type === 'login';
  const _onFacebookClick = () => {
    let _url = app.actions.getFacebookLoginUrl();
    window.open(_url, '_self');
  };
  const _onGoogleClick = () => {
    let _url = app.actions.getGoogleLoginUrl();
    window.open(_url, '_self');
  };
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <h3>{isLogin ? '會員登入' : '會員註冊'}</h3>
      <p>
        {isLogin
          ? '若您已註冊完成可以使用電話號碼進行會員登入\n' +
            '或以直接以社群帳號進行登入'
          : '您可以使用電話號碼進行會員註冊， 您亦可以直接以社群帳號進行登入'}
      </p>
      <div style={{flexBasis: 30}} />
      <Button
        onClick={onClick}
        type="secondary"
        style={{backgroundColor: '#fff'}}>
        {isLogin ? '登入' : '註冊'}
      </Button>
      {/*
      <div style={{flexBasis: 20}} />
      <Button onClick={_onFacebookClick} style={{backgroundColor: '#415799'}}>
        <img
          style={{width: 24, height: 24, marginRight: 10}}
          src={Facebook}
          alt="social-sign-in-icon-f"
        />
        <span>以 Facebook {isLogin ? '登入' : '註冊'}</span>
      </Button>
      */}
      <div style={{flexBasis: 20}} />
      <Button
        onClick={_onGoogleClick}
        type="secondary"
        style={{backgroundColor: '#fff'}}>
        <img
          style={{width: 24, height: 24, marginRight: 10}}
          src={Google}
          alt="social-sign-in-icon-g"
        />
        <span>以 Google {isLogin ? '登入' : '註冊'}</span>
      </Button>
    </div>
  );
};

function ProfilePage(props) {
  const app = React.useContext(AppContext.Context);
  const [editing, setEditing] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [values, setValues] = React.useState({});
  const {dimension, tablet, mobile} = useDimension();
  const [activeTab, setActiveTab] = useState('1');

  React.useEffect(() => {
    if (app.profile && !editing) {
      setValues(app.profile);
    }
  }, [app.profile, editing]);

  const _openLoginModal = React.useCallback(() => {
    AppActions.setModal(
      <LoginModal onClose={() => AppActions.setModal(null)} />,
    );
  }, []);

  const _openPhoneVerifyModal = React.useCallback(() => {
    const _onVerifyPhoneSuccess = async () => {
      AppActions.setModal(null);
      AppActions.setLoading(true);
      await app.actions.refreshProfile();
      AppActions.setLoading(false);
    };
    AppActions.setModal(
      <PhoneVerifyModal
        allowPhoneEdit={!app.profile.phone}
        defaultPhone={app.profile.phone || undefined}
        onSuccess={_onVerifyPhoneSuccess}
      />,
    );
  }, [app.profile]);

  const _onRegiserLinkClick = React.useCallback((e) => {
    e.preventDefault();
    navigate(URL_PATH.register);
  }, []);

  const _onChange = React.useCallback(
    (field) => (value) => {
      let _nextValue = value;
      const NUMBER_CHAR_ONLY_FIELDS = ['phone', 'tel', 'tel_ext'];
      if (NUMBER_CHAR_ONLY_FIELDS.indexOf(field) !== -1) {
        _nextValue = _nextValue.replace(/([\D])/g, '');
      }
      setValues({
        ...values,
        [field]: _nextValue,
      });
    },
    [values],
  );

  const _onAddressDataChange = React.useCallback(
    ({city, district, address, zip}) => {
      setValues({
        ...values,
        city,
        district,
        address,
        zip,
      });
    },
    [values],
  );

  const _onProfileUpdate = React.useCallback(async () => {
    if (RequiredFields.some((f) => !values[f])) {
      message.error('請確認必填欄位都已填寫喔！');
      return;
    }
    if (!isChineseName(values.name)) {
      message.error('請填入中文姓名！');
      return;
    }

    setSubmitting(true);
    try {
      let data = {
        ...app.profile,
        ...values,
      };
      let _updatedProfile = await app.actions.updateProfile(data);
      setEditing(false);
      message.success('會員資料已更新！');
    } catch (err) {
      console.log('err', err);
      message.error(
        '無法更新會員資料！請再試一次。若此問題持續發生，請聯絡客服人員為您處理',
      );
    }
    setSubmitting(false);
  }, [app.actions, app.profile, values]);

  const _onChangePw = React.useCallback(() => {
    AppActions.setModal(
      <ChangePwModalContent onClose={() => AppActions.setModal(null)} />,
    );
  }, []);

  const birthdateDisplay = React.useMemo(() => {
    let result = '';
    if (values.birthdate) {
      try {
        let [year, month, date] = values.birthdate.split('-');
        result = `民國 ${Number(year) - 1911}年 ${month}月 ${date}日`;
      } catch (err) {
        //
      }
    }
    return result;
  }, [values.birthdate]);

  const shouldEditProfile = React.useMemo(() => {
    if (app.profile) {
      if (
        isChineseName(app.profile.name) &&
        RequiredFields.every((f) => !!app.profile[f])
      ) {
        return false;
      }
      return true;
    }

    return false;
  }, [app.profile]);

  const tabList = [
    {
      key: '1',
      tab: '註冊',
    },
    {
      key: '2',
      tab: '登入',
    },
  ];

  const contentList = {
    1: <AuthCard type={'register'} onClick={_onRegiserLinkClick} />,
    2: <AuthCard type={'login'} onClick={_openLoginModal} />,
  };

  if (!app.profile) {
    return (
      <Wrapper>
        <div className="container">
          <img className="logo" src={imgLogo} alt="logo" />
          <p className="welcom-wording">
            歡迎來到法朋烘焙甜點坊
            <br />
            您尚未登入，請登入會員或前往註冊新帳號
          </p>
          <Card
            tabProps={{centered: true}}
            style={{
              borderRadius: 10,
              maxWidth: tablet ? 300 : 400,
              marginBottom: 20,
            }}
            bodyStyle={{padding: '50px 65px 80px 65px'}}
            tabList={tabList}
            activeTabKey={activeTab}
            onTabChange={(key) => {
              setActiveTab(key);
            }}>
            {contentList[activeTab]}
          </Card>
          <p>
            登入時，同步接受{' '}
            <span
              style={{color: '#B79D7A', cursor: 'pointer'}}
              onClick={async () => {
                navigate('/faq?p=privacy-policy');
              }}>
              隱私權政策
            </span>{' '}
            與{' '}
            <span
              style={{color: '#B79D7A', cursor: 'pointer'}}
              onClick={async () => {
                navigate('/faq?p=terms-of-use');
              }}>
              使用條款
            </span>
          </p>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper2 tablet={tablet} editing={editing}>
      <div className="container">
        <div className="sider">
          <ProfileSideNav
            type={tablet ? 'horizontal' : 'vertical'}
            location={props.location}
          />
        </div>
        <div className="content">
          {shouldEditProfile && (
            <div className="should-edit-profile-hint">
              手機驗證為您的會員帳號。請修改您的姓名、生日、地址等必填欄位，加速購買流程
            </div>
          )}
          <div className="header">會員資料</div>
          <div className="form">
            <div className="item">
              <label>
                {editing && RequiredFields.indexOf('name') !== -1 && (
                  <span className="asterisk">*</span>
                )}
                會員姓名
              </label>
              {editing ? (
                <TextInput
                  value={values.name}
                  className={'input'}
                  onChange={(e) => _onChange('name')(e.target.value)}
                />
              ) : (
                <div className="display">{values.name}</div>
              )}
            </div>

            {app.profile.provider === 'default' && (
              <div className="item">
                <label>密碼</label>
                <div className="display">
                  {'********'}
                  {editing && (
                    <AntButton
                      type="ghost"
                      size="small"
                      onClick={_onChangePw}
                      style={{marginLeft: 10}}>
                      變更密碼
                    </AntButton>
                  )}
                </div>
              </div>
            )}

            <div className="item">
              <label>
                {editing && RequiredFields.indexOf('birthdate') !== -1 && (
                  <span className="asterisk">*</span>
                )}
                生日
              </label>
              {!app.profile?.birthdate && editing ? (
                <TextInput
                  type={'date'}
                  value={values.birthdate}
                  className={'input'}
                  onChange={(e) => _onChange('birthdate')(e.target.value)}
                />
              ) : (
                <div className="display">{birthdateDisplay}</div>
              )}
            </div>

            <div className="item">
              <label>手機號碼</label>
              {editing ? (
                <>
                  <TextInput
                    disabled
                    value={values.phone}
                    className={'input'}
                    onChange={(e) => _onChange('phone')(e.target.value)}
                  />
                </>
              ) : (
                <>
                  {values.phone && app.profile.phone_verified ? (
                    <div className="display">{values.phone}</div>
                  ) : (
                    <div className="blank-placeholder">
                      {values.phone || '您的手機號碼尚未驗證！'}
                      <AntButton
                        style={{marginLeft: 10}}
                        type={'primary'}
                        size={'small'}
                        onClick={_openPhoneVerifyModal}>
                        驗證手機
                      </AntButton>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="item">
              <label>市話號碼</label>
              {editing ? (
                <>
                  <TextInput
                    value={values.tel}
                    className={'input'}
                    style={{marginRight: 10}}
                    onChange={(e) => _onChange('tel')(e.target.value)}
                  />
                  <TextInput
                    type={'short'}
                    value={values.tel_ext}
                    className={'input'}
                    onChange={(e) => _onChange('tel_ext')(e.target.value)}
                  />
                </>
              ) : (
                <div className="display">{`${values.tel || ''}${
                  values.tel_ext ? `#${values.tel_ext}` : ''
                }`}</div>
              )}
            </div>

            <div className="item">
              <label>
                {editing && RequiredFields.indexOf('email') !== -1 && (
                  <span className="asterisk">*</span>
                )}
                電子信箱
              </label>
              {editing ? (
                <TextInput
                  value={values.email}
                  className={'input'}
                  onChange={(e) => _onChange('email')(e.target.value)}
                />
              ) : (
                <div className="display">{values.email || ''}</div>
              )}
            </div>

            <div className="item">
              <label>
                {editing && RequiredFields.indexOf('address') !== -1 && (
                  <span className="asterisk">*</span>
                )}
                地址
              </label>
              {editing ? (
                <AddressInput
                  values={{
                    city: values.city,
                    address: values.address,
                    zip: values.zip,
                    district: values.district,
                  }}
                  onChange={_onAddressDataChange}
                />
              ) : (
                <div className="display">
                  {`${values.zip || ''} ${values.city || ''}${
                    values.district || ''
                  }${values.address || ''}`}
                </div>
              )}
            </div>

            <div className="item">
              <label>歸戶驗證碼</label>
              {editing ? (
                <TextInput
                  value={values.carrier_code}
                  className={'input'}
                  style={{marginRight: 10}}
                  disabled
                />
              ) : (
                <>
                  <div className="display" style={{flex: 0, flexBasis: 120}}>
                    {values.carrier_code || ''}
                  </div>
                  <CopyToClipboard
                    text={values.carrier_code || ''}
                    style={{marginLeft: 10}}>
                    <Icon.CopyOutlined
                      size={20}
                      color={'#757575'}
                      style={{padding: 10, margin: '5px 0px'}}
                    />
                  </CopyToClipboard>
                </>
              )}
            </div>
            <div className="item-hint">
              {`* 歸戶驗證碼說明：
歸戶驗證碼不是您的手機載具，為預設的會員載具號碼，故無法修改。
若購買時，選擇二聯發票儲存於會員載具內，
可用此歸戶驗證碼至`}
              <a
                href="https://eci.tradevan.com.tw/APSSIC/gov/gov001!toQueryPage.action"
                target="_blank">
                關貿網頁
              </a>
              {`將發票歸戶到手機載具或自然人憑證內。
歸戶步驟僅需操作一次，後續產生的發票都會自動歸在同張載具名下。
提醒您：
1.已歸戶發票若有中獎，會由財政部通知，法朋不另外通知中獎，領獎資訊將以財政部電子發票整合平台中登記的資訊為主，並由相關單位派發中獎獎金。
2.未歸戶發票，即以託管方式保留於法朋平台，發票中獎將由關貿發送通知email，消費者可於超商機台(ibon/FamiPort）列印實體發票進行兌獎。`}
            </div>
          </div>
          <div className="footer-actions">
            {editing ? (
              <>
                <AntButton
                  style={{marginRight: 10}}
                  onClick={() => setEditing(false)}>
                  取消
                </AntButton>
                <AntButton
                  type={'primary'}
                  loading={submitting}
                  onClick={_onProfileUpdate}>
                  儲存
                </AntButton>
              </>
            ) : (
              <AntButton onClick={() => setEditing(true)}>編輯</AntButton>
            )}
          </div>
        </div>
      </div>
    </Wrapper2>
  );
}

const Wrapper2 = styled.div`
  background: #fff;
  flex: 1;
  & > .container {
    max-width: var(--content-max-width);
    padding: 0px var(--content-padding);
    margin: 0 auto;
    display: flex;

    width: 100%;
    padding-top: 45px;

    & > .sider {
      width: 200px;
    }

    & > .content {
      flex: 1;
      padding: 0px 15px;
      max-width: 600px;

      & > .should-edit-profile-hint {
        color: var(--color-red);
        margin-bottom: 10px;
        font-size: 14px;
      }

      & > .header {
        font-size: 16px;
        margin-bottom: 20px;
      }

      & > .footer-actions {
        margin-top: 40px;
        display: flex;
        justify-content: flex-end;
      }

      & > .form {
        & > .item-hint {
          font-size: 14px;
          color: grey;
          white-space: pre-line;
        }
        & > .item {
          display: flex;
          align-items: flex-start;

          & > label {
            font-size: 14px;
            flex-basis: 100px;
            margin: 5px 10px 5px 0px;
            padding: 10px 0px;

            & > .asterisk {
              color: var(--color-red);
              margin-right: 3px;
            }
          }

          & > .display,
          & > .blank-placeholder {
            font-size: 14px;
            flex-basis: 100px;
            margin: 5px 10px 5px 0px;
            padding: 10px 0px;
            flex: 1;
          }

          & > .blank-placeholder {
            color: #989898;
          }

          & > .input {
          }
        }
      }
    }
  }

  ${(props) =>
    props.tablet
      ? `
    & > .container {
        flex-direction: column;
        align-items: center;
        
        & > .sider {
          width: 600px;
          max-width: 100%;
          margin-bottom: 40px;
        }

        & > .content {
          width: 600px;
          max-width: 100%;

          & > .header {
            display: none;
          }

          & > .form {
            & > .item {
              ${props.editing ? 'flex-wrap: wrap;' : ''}

              & > label {
                ${props.editing ? 'flex-basis: 100%;' : ''}
              }
            }
          }
        }
      }
  `
      : ''}
`;

const Wrapper = styled.div`
  max-width: var(--content-max-width);
  padding: 0px var(--content-padding);
  margin: 0 auto;
  background-image: linear-gradient(179deg, #f0f0ee 0%, #f3f3f2 62%);
  flex: 1;
  width: 100%;
  padding-top: 45px;

  & > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 130px;

    .ant-tabs-nav-list {
      width: 100%;
    }
    .ant-tabs-tab {
      width: 100%;
      justify-content: center;
      margin: 0;
    }

    & > .logo {
      display: block;
      margin: 0px auto 20px auto;
      height: 50px;
    }

    & > .welcom-wording {
      margin-bottom: 75px;
    }

    & > .placeholder-img {
      width: 475px;
      max-width: 100%;

      & > img {
        width: 100%;
      }
    }

    & h3 {
      font-size: 34px;
      line-height: 48px;
      font-weight: bold;
      margin-bottom: 25px;
      color: #595757;
    }

    & p {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
      text-align: center;
      color: #595757;
    }

    & > .hint {
      font-size: 14px;
      color: grey;
      margin-top: 10px;
    }
  }
`;

export default ProfilePage;
