import React from 'react';
import styled from 'styled-components';
import {Input, Select} from 'antd';
import cities from '../domain/address/city.json';
import districts from '../domain/address/district.json';
import {BREAK_POINTS} from '../domain/Constants';

function AddressInput(props) {
  const {values = {}, onChange = () => 0} = props;

  const _onInputChange = React.useCallback(
    (field) => (value) => {
      let _nextValue = value;
      if (field === 'address') {
        // remove " ", ",", "，"
        _nextValue = _nextValue.replace(/[ ,，]/g, '');
      }

      let _data = {
        ...values,
        [field]: _nextValue,
        // district: '',
        // city: '',
        // zip: '',
        // address: '',
      };

      if (field === 'district') {
        let _curActiveDistrictList = districts[values.city];
        let _zip = _curActiveDistrictList.find((d) => d.name === value)?.code;
        _data.zip = _zip;
      } else if (field === 'city') {
        _data.zip = '';
        _data.district = '';
      }

      onChange(_data);
    },
    [onChange, values],
  );

  return (
    <Wrapper>
      {/* <TextInput
        placeholder="郵遞區號"
        type="short"
        value={values.zip || ''}
        css={{marginRight: 5}}
        disabled
      /> */}
      <SelectInput
        style={{marginLeft: 0}}
        placeholder="縣市"
        value={values.city || undefined}
        onChange={_onInputChange('city')}>
        {cities.map((c, idx) => (
          <SelectInputOption key={idx} value={c}>
            {c}
          </SelectInputOption>
        ))}
      </SelectInput>
      <SelectInput
        placeholder="區"
        value={values.district || undefined}
        onChange={_onInputChange('district')}>
        {(districts[values.city] || []).map((d, idx) => (
          <SelectInputOption key={idx} value={d.name}>
            {d.name}
          </SelectInputOption>
        ))}
      </SelectInput>
      <br />
      <TextInput
        placeholder="通訊地址"
        type="long"
        value={values.address}
        onChange={(e) => _onInputChange('address')(e.target.value)}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SelectInput = styled(Select)`
  margin: 5px;
  flex-basis: 100px;
  height: 40px;
  & > .ant-select-selector {
    height: 100% !important;
    align-items: center;
  }
`;

const SelectInputOption = styled(Select.Option)`
  width: 100px;
`;

const TextInput = styled(Input)`
  padding: 8px;
  border: none;
  background-color: #fff;
  border: 1px solid #cecece;
  margin: 6px 0px;
  flex-basis: 220px;
  flex-shrink: 0;
  ${(props) => (props.type === 'short' ? 'flex-basis: 90px; width: 90px;' : '')}
  ${(props) => (props.type === 'long' ? 'flex-basis: 320px;' : '')}

  @media only screen and  (max-width: ${BREAK_POINTS.mobile}px) {
    flex-basis: ${(props) =>
      props.type === 'long' ? '220px' : props.type === 'short' ? '90px' : ''};
    flex-shrink: 1;
  }

  :hover {
    border-color: #b79d7a;
  }

  :focus {
    border-color: #b79d7a;
    outline-color: #b79d7a;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(100, 100, 100, 0.2);
    box-shadow: 0 0 0 2px rgba(100, 100, 100, 0.2);
  }

  .ant-input::selection {
    border-color: green;
  }

  /* ::after {
    border-color: green;
    background: green;
} */

  ${(props) => props.css}
`;

export default AddressInput;
